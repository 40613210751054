import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import * as React from 'react';
import HeroSection from '../components/Blocks/HeroSection/heroSection';
import Paragraph from '../components/Blocks/Paragraph/paragraph';
import PlaceImage from '../components/Blocks/PlaceImage/placeImage';
import Section from '../components/Blocks/Section/section';
import FullscreenPhotoSection from '../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../components/layout';
import PerformanceSection from '../components/PerformanceSection/performanceSection';
import PhotoLeftTextRight from '../components/PhotoLeftTextRight/photoLeftTextRight';
import SEO from '../components/SEO/seo';
import VideoFullscreen from '../components/VideoFullscreen/videoFullscreen';

const TemplatesPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout >
        <SEO lang="en" title={'Design system for social networks'} keywords={['design', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'юзабилити', 'вконтакте']} description="How to design social media posters faster and cheaper. How we made templates in Figma automatically using auto-layout.">
        </SEO>

        <HeroSection animationType="liquiddotloader" heading="How to save money on social media design?" desc="Figma templates for content managers"/>

        <Section heading="Project">
            <Paragraph>Social networks are an important part of a brand, and here you need to stand out, differentiate. The design of social media pages is not new for a long time. But even with a good design, it is difficult to keep track of the consistency and consistency of content design.</Paragraph>
            <Paragraph>Therefore, we at Cactus Vision decided to simplify the work of both the content manager and the designers, and reinforce the unity of the brand.</Paragraph>
        </Section>

        <FullscreenPhotoSection salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" fileURL="templates-overview.png" alt="Komilfo"/>

        <PhotoLeftTextRight salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Designers need to constantly create" fileURL="templates-posters.png" alt="Old hand-maded posters">
            <Paragraph>The biggest problem with designing social media posters is time and money. Designers need to create a unique poster and scale it for each social network. I don't want to hire a social media designer either.</Paragraph>
        </PhotoLeftTextRight>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Simplify poster creation" >
            <Paragraph>An editor is needed to make this process easier. Figma is an obvious answer, designers use it all the time, it is not difficult to learn and it has a feature - auto-layout. Auto-layout allows you to automatically align elements as we tell them.</Paragraph>
            <Paragraph>We selected the main headings for which posters are needed - so that they are unique for each heading.</Paragraph>
        </Section>

        <VideoFullscreen salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" youtubeVideoID="5YfaoixbSpg"/>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Wrote down the guide" >
            <Paragraph>As a UX designer, it's obvious to me that not everyone will be able to understand Figma.</Paragraph>
            <Paragraph>Therefore, for the main features - those without which you cannot create and export a poster, I created a guide in Notion. It is more convenient to explain some points in practice, so I recorded a couple of video guides.</Paragraph>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Result">
            <Paragraph>As a result, at first everything was not so rosy - the content manager needs to adapt, learn to use it, get used to it. Therefore, on some posters, the text is too close to the frame or the text is too small, which made it look inconsistent.</Paragraph>
            <Paragraph>But we quickly found errors, eliminated and supplemented the guide.</Paragraph>
            <Paragraph>This is how we got a design system for social networks. Designers have more time, and the content manager always has a social media poster ready.</Paragraph>
        </Section>
    </Layout>
  ); };

export default TemplatesPage;
